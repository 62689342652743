.cardHome{
    box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
    transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
}

.cardHome:hover{
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}

.homeTitle {
    font-size: 70px;
    font-weight: 700;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    letter-spacing: 0.05em;
    text-align: center;
  }

.homeMessage {
    font-weight: 500;
    font-family: "Rubik";
    text-align: center;
  }

.homeLink {
    text-decoration: none;
    color: inherit;
}

.linked {
  color: rgb(32, 44, 211);
}