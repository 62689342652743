.cardNotFound{
    padding: 2%;
    width: 50%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 6px 10px rgba(0, 0, 0, .08), 0 0 6px rgba(0, 0, 0, .05);
    transition: .3s transform cubic-bezier(.155, 1.105, .295, 1.12), .3s box-shadow, .3s -webkit-transform cubic-bezier(.155, 1.105, .295, 1.12);
}

.cardNotFound:hover{
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}

.notFoundTitle {
    font-size: 50px;
    font-weight: 600;
    font-family: "Rubik";
  }