.cardAbout{
    padding: 2%;
    box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
    transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
}

.cardAbout:hover{
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}


.img{
    border-radius: 10px;
    box-shadow: 0 0px 10px rgba(0,0,0,.5);
}

.aboutTitle {
    font-size: 50px;
    font-weight: 700;
    font-family: "Rubik";
  }

.arrow {
    color: #3888CB;
}