.buttonFooter {
    position: fixed;
    right: 80px;
    bottom: 100px;
    z-index: 1;
}

.heading {
    text-align: center;
    font-family: "rubik";
    text-shadow: 2px 2px 4px #000000;
    font-size: 75px;
    margin-bottom: 0px;
    color: #E1DCD9;
    background-color: #3888CB;
}