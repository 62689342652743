.ContactPageContainer {
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  
  .ContactForm {
    box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
    padding-top: 30px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 8px;
    background-color: white;
  }
  
  .ContactContent {
    padding-left: 12%;
    padding-right: 12%;
  }
  
  .ContactTitle {
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    font-family: "Rubik";
  }

